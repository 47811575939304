import React from 'react'
import Banner from '../../banner'
import SectionOne from './SectionOne'

import './contact.css'


const ContactPage = () => (
<>
    <Banner />
    <SectionOne />
</>
)

export default ContactPage